import {
    API_BASE_URL,
    ACCESS_TOKEN,
    START_ACTION_URL,
    ACTIVE_DAYS_URL,
    REST_TASK_URL,
    CLOSE_ACTION_URL,
    REOPEN_ACTION_URL,
    RECOMPUTE_ACTION_URL,
    FORGOTTEN_PASSWORD_URL,
    FORGOTTEN_PASSWORD_QUERY_URL,
    FORGOTTEN_PASSWORD_CHANGE_URL,
    CREATE_AEROTOW_URL,
    REST_APRSLOGBOOK_ID_URL,
    PASSWORD_CHANGE_URL, APRS_LOGBOOK_FIND,
    DOCUMENT_CHECK_URL, REST_PILOT_URL, MANDATORY_TASKS_URL, PLANE_LOG_BOOK_ITEM_URL, GENERATE_TOKEN_KEY_URL
} from './constants';

const request = async (options, jsonType = true) => {

    const headers = new Headers();

    if (jsonType) {
        headers.append('Content-Type', 'application/json');
    }

    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    try {
        let response = await fetch(options.url, options);
        if (!response.ok) { // Handle all non-2xx responses
            return Promise.reject(response);
        }
        return response.json();
    } catch (exception) {
        if (exception.status === 400 || exception.status === 401) {
            throw exception;
        }
        //console.error(exception);
        return exception;
    }
};


const restRequest = async (options) => {

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    // headers.append('Access-Control-Allow-Origin','"*"');

    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    //console.log(options.url);
    try {
        let response = await fetch(options.url, options);
        let json = await response.json();
        //console.log("Accept resource");
        if (!json) {
            //console.log("Reject resource");
            return Promise.reject(json);
        }
        return json;
    } catch (exception) {
        //console.log(exception);
        return exception;
    }
};

const deleteRequest = async (options) => {

    const headers = new Headers();
    const defaults = {headers: headers};
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }
    options = Object.assign({}, defaults, options);

    let response;
    try {
        response = await fetch(options.url, options);
    } catch (error) {
        console.error("Network error:", error);
        return { statusCode: 0, data: null, error: "Network error" };
    }

    const statusCode = response.status;
    let data = null;

    try {
        data = await response.json();
    } catch (error) {
        //console.warn("Failed to parse JSON:", error);
    }

    return { statusCode, data };
};

export async function getCurrentUser() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
    try {
        const response = await request({
            url: API_BASE_URL + "/user/me",
            method: 'GET'
        });
        //console.log(response)
        return response;
    } catch (error) {
        //console.log(error);
        if (error.statusCode === 401) {
            localStorage.removeItem(ACCESS_TOKEN);
            setTimeout(() => {
                window.location.href = "/login";
            }, 100);
            return Promise.reject(error);
        }
        return Promise.reject(error);  // Forward other errors
    }
}



export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export function uploadCsv(form) {
    return request({
        url: API_BASE_URL + "/users/uploadCsv",
        method: 'POST',
        body: form
    }, false);
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export function getSimpleFlyDay(day) {
  return request({
    url: API_BASE_URL + '/getFlyDay?day='+ day,
    method: 'GET'
  }, true);
}

export function newGlider(glider) {
  return request({
    url: API_BASE_URL + '/gliders',
    method: 'POST',
    body: JSON.stringify(glider)
  });
}

export function createTow(message, day) {
    return request({
        url: CREATE_AEROTOW_URL + day,
        method: 'PUT',
        body: JSON.stringify(message)
    });
}


export function gliders() {
  return request({
    url: API_BASE_URL + '/gliders',
    method: 'GET'
  }, false);
}

export function newPilot(pilot) {
  return request({
    url: API_BASE_URL + '/pilots',
    method: 'POST',
    body: JSON.stringify(pilot)
  });
}

export function pilots() {
  return request({
    url: API_BASE_URL + '/pilots',
    method: 'GET'
  }, false);
}

export function loadResource(resourceUri, apiQuery=null) {
  //console.log("load URL: " + resourceUri);
  let url = resourceUri;
  if (apiQuery) {
    url += "?" + apiQuery;
  }
  return restRequest({
        url: url,
        method: 'GET'
      }
  )
}

export function updateResource(resourceUri, resource) {
  //console.log("update URL: " + resourceUri);
  //console.log("update RESOURCE: " + resource.toSource());
  return restRequest({
        url: resourceUri,
        method: 'PUT',
        body: JSON.stringify(resource)
      }
  )
}


export function patchResource(resourceUri, resource) {
  //console.log("update URL: " + resourceUri);
  //console.log("update RESOURCE: " + resource.toSource());
  return restRequest({
        url: resourceUri,
        method: 'PATCH',
        body: JSON.stringify(resource)
      }
  )
}

export function createResource(resourceUri, resource) {
  console.log("create URL: " + resourceUri);
  return restRequest({
        url: resourceUri,
        method: 'POST',
        body: JSON.stringify(resource)
      }
  )
}

export function deleteResource(resourceUri) {
  //console.log("delete URL: " + resourceUri);
  return deleteRequest({
        url: resourceUri,
        method: 'DELETE',
      }
  )
}

export function setActiveGlobal(resourceUri, activeMessage) {
  //console.log("set active URL: " + resourceUri);
  return restRequest({
        url: resourceUri,
        method: 'POST',
        body: JSON.stringify(activeMessage)
      }
  )
}

export function sendStartAction(activeMessage) {
  //console.log("start event");
  return restRequest({
        url: START_ACTION_URL,
        method: 'PUT',
        body: JSON.stringify(activeMessage)
      }
  )
}

export function sendCloseDate(closeMessage) {
  //console.log("close event");
  return restRequest({
        url: CLOSE_ACTION_URL,
        method: 'POST',
        body: JSON.stringify(closeMessage)
      }
  )
}

export function sendRecompute(glider) {
  //console.log("close event");
  return restRequest({
        url: RECOMPUTE_ACTION_URL,
        method: 'POST',
        body: JSON.stringify(glider)
      }
  )
}

export function sendReOpenDate(reopenMessage) {
  //console.log("close event");
  return restRequest({
        url: REOPEN_ACTION_URL,
        method: 'POST',
        body: JSON.stringify(reopenMessage)
      }
  )
}



export function activeDays(activeDaysRequest) {
  //console.log("activeDays");
  return restRequest({
        url: ACTIVE_DAYS_URL,
        method: 'POST',
        body: JSON.stringify(activeDaysRequest)
      }
  )
}

export function sendForgottenPassword(emailOrUsername) {
    return restRequest({
            url: FORGOTTEN_PASSWORD_URL,
            method: 'POST',
            body: JSON.stringify(emailOrUsername)
        }
    )
}

export function forgottenPasswordQuery(uuid) {
    return request({
        url: FORGOTTEN_PASSWORD_QUERY_URL + '/'+ uuid,
        method: 'GET'
    }, true);

}

export function forgottenPasswordChange(changeRequest) {
    return restRequest({
        url: FORGOTTEN_PASSWORD_CHANGE_URL,
        method: 'POST',
        body: JSON.stringify(changeRequest)
    });
}

export function passwordChange(changeRequest) {
    return restRequest({
        url: PASSWORD_CHANGE_URL,
        method: 'POST',
        body: JSON.stringify(changeRequest)
    });
}


export function getTaskListByGroupUrl(group) {
  return REST_TASK_URL + "/search/findAllByGroup?group=" + group._links.self.href;
}

export function hasRole(role, user) {
    if (!user) {
        console.log("No user for check role: " + role)
        return false;
    }
    if (!user.roles) {
        console.log("User has no role: " + role)
        return false;
    }
    return user.roles.indexOf(role) !== -1;
}

export function aprsLogbookId(id) {
    return loadResource(REST_APRSLOGBOOK_ID_URL + "/"+id, null);
}


export function pilotById(id) {
    return loadResource(REST_PILOT_URL + "/"+id, null);
}


export function mandatoryTasks(year) {
    return request({
        url: MANDATORY_TASKS_URL,
        method: 'POST',
        body: JSON.stringify({year: year})
    });
}

export function aprsLogbookFind(message) {
    return restRequest({
            url: APRS_LOGBOOK_FIND,
            method: 'POST',
            body: JSON.stringify(message)
        }
    )
}

export function documentCheckByGlider(gliderId) {
    return restRequest({
            url: DOCUMENT_CHECK_URL + `/glider/${gliderId}`,
            method: 'GET'
        }
    )
}

export function getPlaneLogBookItems(gliderId) {
    return restRequest({
            url: PLANE_LOG_BOOK_ITEM_URL + `/glider/${gliderId}`,
            method: 'GET'
        }
    )
}

export function generateTokenKey() {
    return restRequest({
            url: GENERATE_TOKEN_KEY_URL,
            method: 'GET'
        }
    )
}


export function documentCheckSave(documentCheck) {
    //console.log("documentCheckSave");
    //console.log(documentCheck);
    return restRequest({
            url: DOCUMENT_CHECK_URL,
            method: 'POST',
            body: JSON.stringify(documentCheck)
        }
    )
}

export function documentCheckUpdate(documentCheck) {
    //console.log("documentCheckSave");
    //console.log(documentCheck);
    return restRequest({
            url: DOCUMENT_CHECK_URL,
            method: 'PATCH',
            body: JSON.stringify(documentCheck)
        }
    )
}

export function documentCheckDelete(documentCheck) {
    //console.log(documentCheck);
    return restRequest({
            url: DOCUMENT_CHECK_URL,
            method: 'DELETE',
            body: JSON.stringify(documentCheck)
        }
    )
}

// export function errorsMessage(errorArray) {
//     var res = "";
//     if (Array.isArray(errorArray)) {
//         res = errorArray.reduce(  ( r, currentValue ) => r + currentValue.message, res);
//     }
//     return res;
// }
export function errorsMessage(errors) {
    if (!errors || !Array.isArray(errors) || errors.length === 0 ) return null;

    return errors.map(error => error.message ? error.message : error).join(",\n ");

//     return (
//         <div style={{ color: "red" }}>
//             {errors.map((error, index) => (
//                 <p key={index}>{(error.message ? error.message : error)}</p>
//             ))}
//         </div>
//     );
}


export class createFlyDay {
}

export class focusElement {
}