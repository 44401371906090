import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {checkmarkCircle, create, trash} from "ionicons/icons";
import {ROLE_ADMIN, ROLE_TECHNICIAN, ROLE_USER} from "../common/constants";
import {rolesToNames} from "../common/UserUtils";

import React, {Component, useState} from 'react';
import {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonFooter,
    IonIcon, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonAlert
} from '@ionic/react';

export const UserForm = ({
                             entity = {
                                 name: "",
                                 surname: "",
                                 email: "",
                                 sendClosedPdf: false,
                                 sendDocumentWarn: false,
                                 namedRoles: ["ROLE_USER"],
                                 active: true
                             },
                             onSave = f => f,
                             update = false
                         }) => {

    const [getChangePassword, setChangePassword] = useState(false);
    const [active, setActive] = useState(entity.active);
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [pilotId, setPilotId] = useState(entity.pilotRelation);
    const [sendClosedPdf, setSendClosedPdf] = useState(entity.sendClosedPdf);
    const [sendDocumentWarn, setSendDocumentWarn] = useState(entity.sendDocumentWarn);

    // Store form fields in state to prevent disappearing values
    const [name, setName] = useState(entity.name);
    const [surname, setSurname] = useState(entity.surname);
    const [email, setEmail] = useState(entity.email);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    const submit = e => {
        e.preventDefault();
        var disabled = !submitEnabled();
        setPasswordMessage(disabled);
        if (disabled) return;

        let needPassword = !update || getChangePassword;
        const user = {
            name: name.trim(),
            surname: surname.trim(),
            email: email.trim(),
            namedRoles: entity.namedRoles,
            sendClosedPdf: sendClosedPdf,
            sendDocumentWarn: sendDocumentWarn,
            active: active,
            changePassword: needPassword,
            password: needPassword ? password : null,
            passwordAgain: needPassword ? passwordAgain : null,
            pilotId: pilotId
        };

        onSave(user);
    };

    const submitEnabled = () => {
        return (update && !getChangePassword) || (password && passwordAgain && password === passwordAgain);
    };

    const rolesChanged = e => {
        if (!e.target.value) return;
        entity.namedRoles = e.target.value;
    };

    return (
        <form onSubmit={submit}>
            <IonList>
                <IonItem>
                    <IonLabel position="stacked">Jméno</IonLabel>
                    <IonInput value={name} onIonInput={e => setName(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Příjmení</IonLabel>
                    <IonInput value={surname} onIonInput={e => setSurname(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput value={email} onIonInput={e => setEmail(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat PDF po uzavření dne</IonLabel>
                    <IonCheckbox checked={sendClosedPdf} onIonChange={e => setSendClosedPdf(e.detail.checked)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat upozornění o expiraci dokumentu</IonLabel>
                    <IonCheckbox checked={sendDocumentWarn} onIonChange={e => setSendDocumentWarn(e.detail.checked)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Aktivní</IonLabel>
                    <IonCheckbox checked={active} onIonChange={e => setActive(e.detail.checked)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Role</IonLabel>
                    <IonSelect onIonChange={rolesChanged} value={entity.namedRoles} multiple={true}>
                        <IonSelectOption key={ROLE_USER} value={ROLE_USER}>Uživatel</IonSelectOption>
                        <IonSelectOption key={ROLE_TECHNICIAN} value={ROLE_TECHNICIAN}>Technik</IonSelectOption>
                        <IonSelectOption key={ROLE_ADMIN} value={ROLE_ADMIN}>Administrátor</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {update && entity.pilotRelationSuggestion && (
                    <IonItem>
                        <IonLabel position="stacked">Pilot</IonLabel>
                        <IonSelect onIonChange={e => setPilotId(e.detail.value)} value={pilotId}>
                            {entity.pilotRelationSuggestion.map((pilot) => (
                                <IonSelectOption key={pilot.id} value={pilot.id}>
                                    {pilot.name + ' ' + pilot.surname}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                )}
                {update && (
                    <IonItem>
                        <IonLabel position="stacked">Změnit heslo</IonLabel>
                        <IonCheckbox checked={getChangePassword} onIonChange={e => setChangePassword(e.detail.checked)} />
                    </IonItem>
                )}
                {(!update || getChangePassword) && (
                    <>
                        <IonItem>
                            <IonLabel position="stacked">Heslo</IonLabel>
                            <IonInput type="password" value={password} onIonInput={e => setPassword(e.detail.value)} autocomplete="off" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Heslo znovu</IonLabel>
                            <IonInput type="password" value={passwordAgain} onIonInput={e => setPasswordAgain(e.detail.value)} />
                        </IonItem>
                        {passwordMessage && (
                            <div style={{ color: "red", padding: 5, paddingLeft: 12, fontSize: "smaller" }}>
                                Hesla nejsou shodná.
                            </div>
                        )}
                    </>
                )}
            </IonList>
            <IonFooter>
                <IonButton expand="full" type="submit">Uložit</IonButton>
            </IonFooter>
        </form>
    );
};





class User extends Component {
    constructor(props) {

        super(props);
        this.state = {
            entity: props.entity,
            editModal: false,
            confirmDelete: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entity._links.self.href !== this.props.entity._links.self.href) {
            this.setState({ entity: this.props.entity });
        }
    }

    showModal = () => {
        this.setState({ editModal: true });
    };

    closeModal = () => {
        this.setState({ editModal: false });
    };

    setConfirmDelete = (value) => {
        this.setState({ confirmDelete: value });
    };

    onUpdate = async (entityToUpdate) => {
        this.closeModal();
        const { entity } = this.state;
        const updatedEntity = await updateResource(entity._links.self.href, entityToUpdate);
        this.setState({ entity: updatedEntity });
    };

    render() {
        const { entity, editModal, confirmDelete } = this.state;
        const { active, deleteEntity, setEntity = (f) => f } = this.props;

        if (!entity) {
            return <IonItem>Chyba</IonItem>;
        }

        const isActive =
            entity && active && active.entity && active.entity._links.self.href === entity._links.self.href;

        return (
            <>
                <IonItem color={!entity.active ? "medium" : ""}>
                    <IonLabel onClick={() => setEntity(entity)}>
                        <h2>
                            {entity.surname} {entity.name} |{" "}
                            <small>{rolesToNames(entity.namedRoles)}</small>
                        </h2>
                        <h3>
                            {entity.email} | <small>{entity.username}</small>{" "}
                            {entity.pilotRelation && (
                                <IonIcon size={"medium"} icon={checkmarkCircle} style={{ color: "green", verticalAlign: "middle" }} />
                            )}
                        </h3>
                    </IonLabel>
                    <IonButtons slot={"end"}>
                        <IonButton size="small" onClick={() => this.setConfirmDelete(true)}>
                            <IonIcon icon={trash} />
                        </IonButton>
                        <IonButton size="small" onClick={this.showModal}>
                            <IonIcon icon={create} />
                        </IonButton>
                    </IonButtons>

                    <IonModal isOpen={editModal} backdropDismiss={false}>
                        <IonHeader translucent={true}>
                            <IonToolbar>
                                <IonTitle>Editace uživatele</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={this.closeModal}>Zavřít</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <UserForm entity={entity} onSave={this.onUpdate} update={true} />
                        </IonContent>
                    </IonModal>

                    <IonAlert
                        isOpen={confirmDelete}
                        onDidDismiss={() => this.setConfirmDelete(false)}
                        header={"Smazání uživatele"}
                        message={`Opravdu chcete smazat uživatele: <strong>${entity.name}</strong>?`}
                        buttons={[
                            {
                                text: "Ne",
                                role: "cancel",
                                cssClass: "secondary",
                                handler: () => {},
                            },
                            {
                                text: "Ano",
                                handler: () => {
                                    deleteEntity(entity);
                                },
                            },
                        ]}
                    />
                </IonItem>
            </>
        );
    }
}

export default User;