import React from "react";
import { IonPage, IonSplitPane } from "@ionic/react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { StompSessionProvider } from "react-stomp-hooks";

import VisibleSideMenu from "./menu/SideMenuWithRouter";
import LoginHandler from "./login/LoginHandler";
import Login from "./login/Login";
import FlyDay from "./flyday/FlyDay";
import { formatDate } from "./common/TimeUtils";
import Logbook from "./logbook/Logbook";
import PlacePage from "./pages/PlacePage";
import TaskPage from "./pages/TaskPage";
import TrackingPage from "./tracking/TrackingPage";
import About from "./pages/About";
import SettingsPage from "./settings/SettingsPage";
import UsersPage from "./users/UsersPage";
import ForgottenPassword from "./login/ForgottenPassword";
import AprsFlights from "./arps/AprsFlights";
import AprsLogbookPage from "./arps/AprsLogbookPage";
import { hasRole } from "./common/ApiUtils";
import { ROLE_ADMIN, WS_URL } from "./common/constants";
import ChangePasswordPage from "./users/ChangeAccountPage";
import GlidersPage from "./pages/GlidersPage";
import PilotsPage from "./pages/PilotsPage";
import { stompClientConnectionDispatch } from "./reduce/actions";

const MainApp = ({ isAuthenticated, currentUser, stompConnectionDispatch }) => {
    return (
        <IonSplitPane contentId="main">
            {isAuthenticated && currentUser && <VisibleSideMenu />}
            <LoginHandler />
            <IonPage id="main">
                <Routes>
                    <Route path="/aprslogbook" element={<AprsLogbookPage />} />

                    {!isAuthenticated ? (
                        <>
                            <Route path="/forgottenPassword/:uuid" element={<ForgottenPassword />} />
                            <Route path="/login" element={<Login />} />
                        </>
                    ) : (
                        <>
                            <Route
                                path="/flyday/:dateParam"
                                element={
                                    <StompSessionProvider
                                        url={WS_URL}
                                        heartbeatIncoming={1000}
                                        heartbeatOutgoing={1000}
                                        onConnect={() => stompConnectionDispatch(true)}
                                        reconnectDelay={2000}
                                        connectionTimeout={3000}
                                        onWebSocketClose={() => stompConnectionDispatch(false)}
                                    >
                                        <FlyDay />
                                    </StompSessionProvider>
                                }
                            />
                            <Route path="/flyday" element={<Navigate to={`/flyday/${formatDate(Date.now())}`} />} />
                            <Route path="/" element={<Navigate to={`/flyday/${formatDate(Date.now())}`} />} />
                            <Route path="/gliders" element={<GlidersPage />} />
                            <Route path="/pilots" element={<PilotsPage />} />
                            <Route path="/aprs" element={<StompSessionProvider url={WS_URL}><AprsFlights /></StompSessionProvider>} />
                            <Route path="/logbook" element={<Logbook />} />
                            <Route path="/place" element={<PlacePage />} />
                            <Route path="/task" element={<TaskPage />} />
                            {hasRole(ROLE_ADMIN, currentUser) && <Route path="/users" element={<UsersPage />} />}
                            <Route path="/user" element={<ChangePasswordPage currentUser={currentUser} />} />
                            <Route path="/tracking" element={<TrackingPage />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/login" element={<Navigate to="/" />} />
                        </>
                    )}
                </Routes>
            </IonPage>
        </IonSplitPane>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state?.isAuthenticated || false,
    currentUser: state?.currentUser || null,
});

const mapDispatchToProps = (dispatch) => ({
    stompConnectionDispatch: (connectionStatus) => dispatch(stompClientConnectionDispatch(connectionStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
