import React from 'react';
import './Login.css';
import LoginForm from "./LoginForm";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";

import { connect } from "react-redux";
import { onLogin } from "../reduce/actions";
import { useNavigate } from "react-router-dom";

const Login = ({ dispatchOnLogin }) => {
    const navigate = useNavigate(); // React Router v6 navigation

    const onLoginHandle = (response) => {
        dispatchOnLogin(response);
        navigate("/"); // Redirect to home page
        window.location.reload();
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Přihlášení</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="login-background">
                <LoginForm onLogin={onLoginHandle} />
            </IonContent>
        </IonPage>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatchOnLogin: (load) => dispatch(onLogin(load)),
});

export default connect(null, mapDispatchToProps)(Login);
