import { connect } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { onLogin } from "../reduce/actions";
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { forgottenPasswordChange, forgottenPasswordQuery } from "../common/ApiUtils";

const ForgottenPassword = (props) => {
    const navigate = useNavigate();
    const { uuid } = useParams(); // Access params using useParams in v6

    const [data, setData] = useState({ user: null, message: null });
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [done, setDone] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    useEffect(() => {
        async function fetchData() {
            const result = await forgottenPasswordQuery(uuid); // Use uuid directly from useParams
            if (result.message) {
                setData({ user: null, message: result.message });
            } else {
                setData({ user: result, message: null });
            }
        }
        if (uuid) fetchData(); // Only fetch data if uuid is present
    }, [uuid]);

    if (!uuid) return <></>;

    const submitEnabled = () => {
        return password && passwordAgain && password === passwordAgain;
    };

    const showError = () => {
        return (
            <div
                style={{
                    color: "red",
                    padding: 5,
                    paddingLeft: 12,
                    fontSize: "smaller"
                }}
            >
                Hesla nejsou shodná.
            </div>
        );
    };

    const changePasswordChange = async (entity) => {
        const result = await forgottenPasswordChange(entity);
        if (result) {
            setDone(true);
        }
    };

    const submit = (e) => {
        e.preventDefault();
        const disabled = !submitEnabled();
        setPasswordMessage(disabled);
        if (disabled) {
            return;
        }

        const entity = {
            uuid: uuid,
            password,
            passwordAgain
        };
        changePasswordChange(entity);
    };

    if (done) return <Navigate to="/login" />; // Use Navigate for redirect

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Obnova zapomenutého hesla</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {data && data.user && (
                    <form onSubmit={submit}>
                        <IonList>
                            <IonItem>
                                <IonLabel position="stacked">Jméno</IonLabel>
                                <IonInput type={"text"} value={data.user.name} disabled={true} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Uživatelké jméno pro přihlášení</IonLabel>
                                <IonInput type={"text"} value={data.user.username} disabled={true} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Email</IonLabel>
                                <IonInput type={"text"} value={data.user.email} disabled={true} />
                            </IonItem>

                            <IonItem>
                                <IonLabel position="stacked">Nové heslo</IonLabel>
                                <IonInput
                                    type={"password"}
                                    value={password}
                                    onIonChange={(e) => setPassword(e.target.value)}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Nové heslo znovu</IonLabel>
                                <IonInput
                                    type={"password"}
                                    value={passwordAgain}
                                    onIonChange={(e) => setPasswordAgain(e.target.value)}
                                />
                            </IonItem>
                            {passwordMessage && showError()}
                            <IonFooter>
                                <IonButton expand={"full"} type="submit" disabled={!submitEnabled()}>
                                    Uložit
                                </IonButton>
                            </IonFooter>
                        </IonList>
                    </form>
                )}
                {data && data.message && (
                    <IonList>
                        <IonItem>
                            <IonText color="danger">
                                <h4>{data.message}</h4>
                            </IonText>
                        </IonItem>
                    </IonList>
                )}
            </IonContent>
        </IonPage>
    );
};

const mapDispatchToProps = function (dispatch) {
    return {
        dispatchOnLogin(load) {
            dispatch(onLogin(load));
        }
    };
};

export default connect(null, mapDispatchToProps)(ForgottenPassword);
