import React, { useEffect, useRef } from "react";
import { IonChip, IonIcon } from "@ionic/react";
import { TimeType } from "../common/constants";
import { TimeInput } from "./TimeInput";
import { copy, create } from "ionicons/icons";
import TimeSubtraction from "../common/TimeSubtraction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";  // Use the useNavigate hook from react-router-dom
import { activeDispatch, filterGlider, filterPilot, filterTowingPilot, filterTowingPlane } from "../reduce/actions";
import { firstLetter } from "../common/StringUtils";
import { timestampToHuman } from "../common/TimeUtils";

const rowClassName = (index) => {
  return index % 2 === 0 ? "odd" : "even";
};

const AeroTowCls = ({
                      id,
                      uuid,
                      towingPlane,
                      lifter,
                      glider,
                      first,
                      second,
                      start,
                      towingPlaneLanding,
                      gliderLanding,
                      note,
                      gliderTask,
                      towTask,
                      userName,
                      updatedDate,
                      towTaskChange,
                      closed,
                      index,
                      typeIndex,
                      timeChange = (f) => f,
                      detail = (f) => f,
                      taskChange = (f) => f,
                      tow,
                      filterPilotDispatch,
                      filterGliderDispatch,
                      filterTowingPlaneDispatch,
                      filterTowingPilotDispatch,
                      mark = false,
                      scrollToPosition = (f) => f,
                      setActive = (f) => f,
                      active,
                      changeActive,
                      canReopen,
                      currentUser,
                    }) => {
  let refRow = useRef(null);
  const navigate = useNavigate();  // Initialize useNavigate

  useEffect(() => {
    if (refRow && refRow.current && mark) {
      setTimeout(() => {
        scrollToPosition(refRow.current.offsetTop);
      }, 100);
    }
  }, [mark]);

  const isOwnerOfGlider = (glider) => {
    return currentUser && currentUser.id === glider.ownerUserId;
  };

  return (
      <>
        <tr
            className={rowClassName(index) + (mark ? " mark-error" : "")}
            title={closed && userName ? userName + " @ " + timestampToHuman(updatedDate) : ""}
        >
          <td rowSpan={note ? 3 : 2} className={"start-time relative"} ref={refRow}>
            {!closed && (
                <div>
                  <IonChip onClick={() => detail(uuid)} className={"edit-tow"}>
                    uprav <IonIcon icon={create} />
                  </IonChip>
                </div>
            )}
            <span
                className={"type-copy"}
                onClick={() => setActive(tow, active, changeActive)}
            >
            <strong className={"type-count"}>A{typeIndex + 1}</strong>
            <IonIcon className={"copy-icon"} icon={copy} title={"Do konfigurace"} />
          </span>
            <TimeInput
                className={"start-time"}
                value={start}
                id={id}
                type={TimeType.START_TIME}
                timeChange={timeChange}
                disabled={closed}
            />
          </td>
          <td className={"plane-tow"}>
            <a className={"btn btn-link"} onClick={() => filterTowingPlaneDispatch(towingPlane)}>
              {towingPlane.name}
              <br />
              <small>{towingPlane.registration}</small>
            </a>
          </td>
          <td className={"pilot-tow"}>
            <a className={"btn btn-link"} onClick={() => filterTowingPilotDispatch(lifter)}>
              {lifter.surname} {firstLetter(lifter.name)}
            </a>
          </td>
          <td className={"tow-task"}>
            <span>{towTask ? towTask.name : "Úloha"}</span>
          </td>
          <td className={"tow-landing"}>
            <TimeInput
                value={towingPlaneLanding}
                id={id}
                type={TimeType.LANDING_TOWING_TIME}
                timeChange={timeChange}
                min={start}
                disabled={closed}
            />
            <TimeSubtraction t1={start} t2={towingPlaneLanding} />
          </td>
        </tr>
        <tr
            className={rowClassName(index) + (mark ? " mark-error" : "")}
            title={closed && userName ? userName + " @ " + timestampToHuman(updatedDate) : ""}
        >
          <td className={"plane-glider"}>
            <a className={"btn btn-link"} onClick={() => filterGliderDispatch(glider)}>
              {glider.name}
              <br />
              <small>{glider.registration}</small>
            </a>
          </td>
          <td className={"pilot-glider"}>
            <a className={"btn btn-link"} onClick={() => filterPilotDispatch(first)}>
              {first.surname} {firstLetter(first.name)}
            </a>
            {second ? (
                <>
                  <br />
                  <a className={"btn btn-link"} onClick={() => filterPilotDispatch(second)}>
                    {second.surname + " " + firstLetter(second.name)}
                  </a>
                </>
            ) : (
                ""
            )}
          </td>
          <td className={"glider-task"}>
            {!closed || canReopen || !gliderTask ? (
                <IonChip className={"font-l"} onClick={() => taskChange(tow)}>
                  {gliderTask ? gliderTask.name : "Úloha"}
                </IonChip>
            ) : (
                <span>{gliderTask ? gliderTask.name : "Úloha"}</span>
            )}
          </td>
          <td className={"glider-landing"}>
            <TimeInput
                value={gliderLanding}
                min={start}
                id={id}
                type={TimeType.LANDING_GLIDER_TIME}
                timeChange={timeChange}
                disabled={closed && !isOwnerOfGlider(glider)}
            />
            <TimeSubtraction t1={start} t2={gliderLanding} />
          </td>
        </tr>
        {note && (
            <tr className={rowClassName(index)}>
              <td colSpan={"4"}>{note}</td>
            </tr>
        )}
      </>
  );
};

const mapStateToProps = (state) => {
  return {
    active: state ? state.active : {},
    canReopen: state ? state.canReopen : false,
    currentUser: state ? state.currentUser : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterPilotDispatch: (load) => dispatch(filterPilot(load)),
    filterGliderDispatch: (load) => dispatch(filterGlider(load)),
    filterTowingPlaneDispatch: (load) => dispatch(filterTowingPlane(load)),
    changeActive: (load) => dispatch(activeDispatch(load)),
    filterTowingPilotDispatch: (load) => dispatch(filterTowingPilot(load)),
  };
};

const AeroTow = connect(mapStateToProps, mapDispatchToProps)(AeroTowCls);

export default AeroTow;
