import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/l10n/cs.js";
import "./CustomCalendar.css";
import React, { useState, useEffect } from 'react';
import { activeDays } from "../common/ApiUtils";
import { formatDate } from "../common/TimeUtils";
import { useNavigate } from "react-router-dom";

const CustomCalendar = ({ date: initialDate }) => {
  const [date, setDate] = useState(initialDate);
  const [enabledDays, setEnabledDays] = useState([initialDate]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!enabledDays.some(d => d === initialDate)) {
      setEnabledDays(prevDays => [...prevDays, initialDate]);
    }

    setDate(initialDate);
  }, [initialDate]);

  const fetchActiveDays = async (month, year) => {
    try {
      const response = await activeDays({ year, month });
      let enabled = response.enabled ? [...response.enabled] : [];

      const today = new Date();
      if (month === today.getMonth() + 1 && year === today.getFullYear()) {
        var todayFormated = today.getDate() + "." +(today.getMonth() +1)+ "." + today.getFullYear();
        enabled.push(todayFormated);
      }

      setEnabledDays(enabled.length > 0 ? enabled : [date => false]);
    } catch (error) {
      console.error("Failed to fetch active days:", error);
    }
  };

  const onSelectDate = (selectedDates, dateStr, instance) => {
    fetchActiveDays(instance.currentMonth + 1, instance.currentYear);
  };

  const onDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      navigate(`/flyday/${formatDate(selectedDates[0])}`);
    }
  };

  return (
      <Flatpickr
          value={date}
          options={{
            dateFormat: "d.m.Y",
            enable: enabledDays,
            locale: "cs",
          }}
          onOpen={onSelectDate}
          onMonthChange={onSelectDate}
          onYearChange={onSelectDate}
          onChange={onDateChange}
      />
  );
};

export default CustomCalendar;
