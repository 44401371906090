import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useSelector, useDispatch, connect} from "react-redux";
import {
  getSimpleFlyDay, pilotById,
  sendStartAction,
  setActiveGlobal,
  updateResource
} from "../common/ApiUtils";
import {
  REST_PILOT_URL,
  ActiveSelect,
  SET_ACTIVE_URL, TimeType, REST_AEROTOW_URL, PilotType, DetailType, concatUrlId, DOWNLOAD_PDF_URL
} from "../common/constants";
import {
  IonButton,
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonLabel,
  IonFooter,
  IonList,
  IonItem,
  IonToast,
  IonMenuButton,
  IonIcon,
  IonButtons,
  IonToggle, IonChip, IonGrid
} from "@ionic/react";
import { ActiveGlider } from "./ActiveGlider";

import { TowingPlaneList } from "../towingPlane/TowingPLaneList";
import UtcTime from "../common/UtcTime";
import AeroTowList, { filterList } from "../aerotow/AeroTowList";
import "./FlyDay.css"
import AeroTowDetail from "../aerotow/AeroTowDetail";
import {
  convTime, reverseDateToHuman,
  reverseDateToLocalDate,
  subtractTimeArr,
  timeArrayCorrection, timeArrToString
} from "../common/TimeUtils";
import CustomCalendar from "./CustomCalendar";
import {
  activeDispatch,
  filterGlider,
  filterIncomplete,
  filterPilot, filterTowingPilot,
  filterTowingPlane, flyDayDispatch,
  onCloseReceiveFalse, stompClientConnectionDispatch
} from "../reduce/actions";
import { findGliderFce, findPilotFce } from "../common/FindFuctions";
import { PlaneBookDayItems } from "./PlaneBookDayItems";
import { PlaneView } from "./PlaneView";
import { PilotView } from "../pilot/PilotView";
import { arrowBack, chevronForwardOutline, closeCircle, funnel, menu } from "ionicons/icons";
import GliderTaskCompose from "../task/GliderTaskCompose";
import TowTaskCompose from "../towtask/TowTaskCompose";
import { completePilotName } from "../common/StringUtils";
import GliderListRO from "../glider/GliderListRO";
import PilotROList from "../pilot/PilotROList";
import { useSubscription } from "react-stomp-hooks";

// Subscribing component for STOMP messages
const SubscribingComponent = ({ dayDate }) => {
  const dispatch = useDispatch();

  //console.log("SubscribingComponent for date " + dayDate);

  const onMessageReceived = (payload) => {
    let message = JSON.parse(payload.body);
    //console.log("STOMP message received: " + message.statusCodeValue);
    if (message.statusCodeValue === 200) {
      let flyDayMessage = message.body;
      if (dayDate !== flyDayMessage.day) {
        console.log("dayDate " + dayDate + " !== " + flyDayMessage.day);
        return;
      }
      console.log("STOMP message for date " + flyDayMessage.day);
      dispatch(flyDayDispatch(flyDayMessage));
    }
  };

  useSubscription("/topic/flyday", (payload) => onMessageReceived(payload));

  return (
      <></>
  );
};


const convertDateMinusToDot = (date) => {
  if (date === null) return null;
  if (date instanceof Date) {
    return 'Date';
  } else if (typeof date === 'string') {
    return date.replaceAll('-', '.');
  } else {
    return null;
  }

}

const FlyDay = () => {
  // React Router v6 hooks
  const params = useParams();
  const { dateParam } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const onCloseMessage = useSelector(state => state?.onCloseMessage);
  const onCloseMessageChange = useSelector(state => state?.onCloseMessageChange);
  const filterPilotSelector = useSelector(state => state?.filterPilot);
  const filterGliderSelector = useSelector(state => state?.filterGlider);
  const filterTowingPlaneSelector = useSelector(state => state?.filterTowingPlane);
  const filterTowingPilotSelector = useSelector(state => state?.filterTowingPilot);
  const canDownloadPdf = useSelector(state => state?.canDownloadPdf);
  const day = useSelector(state => state?.day || "");
  const active = useSelector(state => state?.active || {});
  const incompleteFilter = useSelector(state => state?.incompleteFilter || false);
  const reduxFlyDay = useSelector(state => state?.flyDay);
  const flyDayStompConnectionStatus = useSelector(state => state?.flyDayStompConnectionStatus || false);

  // Component state
  const [response, setResponse] = useState(false);
  const [username, setUsername] = useState('');
  const [channelConnected, setChannelConnected] = useState(false);
  const [error, setError] = useState('');
  const [flyDay, setFlyDay] = useState(null);
  const [type, setType] = useState(ActiveSelect.HOME);
  const [detailId, setDetailId] = useState(-1);
  const [detailUUID, setDetailUUID] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [editTow, setEditTow] = useState(null);
  const [lastPositionY, setLastPositionY] = useState(0);
  const [reconnectTimeOut, setReconnectTimeOut] = useState(0);

  // Dispatch actions
  const changeDayDispatch = (payload) => dispatch(flyDayDispatch(payload));
  const stompConnectionDispatch = (status) => dispatch(stompClientConnectionDispatch(status));

  const contentRef = useRef(null);
  let intervalID = null;

  // Handle visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      getFlyDay();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Component mount logic
  useEffect(() => {
    //console.log(dateParam);
    getFlyDay();
  }, [dateParam]); // Reload data if `dateParam` changes

  // Get fly day data
  const getFlyDay = async () => {
    const response = await getSimpleFlyDay(dateParam);
    _messageReceived(response);
  };

  // Handle received message (common function for API and WebSocket)
  const _messageReceived = (fd) => {
    if (!fd) {
      console.log("Error, not flyday");
      return;
    }

    let dayDate = convertDateMinusToDot(dateParam);

    if (!flyDay || fd.day === dayDate) {
      if (!active.glider && fd.active) {
        dispatch(flyDayDispatch(fd));
        dispatch(activeDispatch(fd.active));
      } else {
        dispatch(flyDayDispatch(fd));
      }
    }
  };

  // Set active selection type
  const activeSelectFce = (activeSelectType) => {
    setType(activeSelectType);
  };

  // Navigation functions
  const selectHome = () => {
    activeSelectFce(ActiveSelect.HOME);
    if (lastPositionY > 0) {
      setTimeout(() => {
        scrollToPosition(lastPositionY);
        setLastPositionY(0);
      }, 100);
    }
  };

  const selectActiveDetail = () => {
    activeSelectFce(ActiveSelect.ACTIVE_DETAIL);
  };

  const selectTowDetail = () => {
    activeSelectFce(ActiveSelect.TOW_DETAIL);
  };

  // Set active values
  const setActive = (type, value) => {
    let newActive = {...active};
    newActive[type] = value;

    let message = {type: type};
    message[type] = value;

    setActiveGlobal(SET_ACTIVE_URL, message);
    dispatch(activeDispatch(newActive));
  };

  // Handle pilot, glider, and towing plane selection
  const setPilot = (pilot) => {
    if (type === ActiveSelect.FIRST) {
      setActive('first', pilot);
    } else if (type === ActiveSelect.SECOND) {
      setActive('second', pilot);
    } else if (type === ActiveSelect.LIFTER) {
      setActive('lifter', pilot);
    }
    selectActiveDetail();
  };

  const setGlider = async (glider) => {
    setActive('glider', glider);
    if (glider && glider.foreignFlag && glider.ownerId) {
      setActive('first', await pilotById(glider.ownerId));
    }
    selectActiveDetail();
  };

  const setTowingPlane = (plane) => {
    setActive('towingPlane', plane);
    selectActiveDetail();
  };

  // Show detail for a specific tow
  const showDetail = (uuid) => {
    setDetailUUID(uuid);
    selectTowDetail();
  };

  // Handle time changes
  const timeChange = async (id, type, value) => {
    if (!type) return;

    let tow = {...flyDay.tows.filter(item => item.id === id)[0]};

    if (type === TimeType.START_TIME) {
      if (!value) return;
      tow.start = convTime(value);
    } else if (type === TimeType.LANDING_GLIDER_TIME) {
      tow.gliderLanding = value ? convTime(value) : null;
    } else if (type === TimeType.LANDING_TOWING_TIME) {
      tow.towingPlaneLanding = value ? convTime(value) : null;
    }

    let response = await updateResource(REST_AEROTOW_URL, tow);
    if (response.error) {
      setShowToastMessage(true, response.message);
    }
  };

  // Handle tow changes
  const changeTow = (id, type, value) => {
    let tow = {...flyDay.tows.filter(item => item.id === id)[0]};

    if (type === PilotType.TOWING_PILOT) {
      if (!value) return;
      tow.lifter = value;
    } else if (type === PilotType.GLIDER_FIRST) {
      if (!value) return;
      tow.first = value;
    } else if (type === PilotType.GLIDER_SECOND) {
      if (!value) return;
      tow.second = value;
    } else if (type === DetailType.NOTE) {
      tow.note = value;
    }

    updateResource(REST_AEROTOW_URL, tow);
  };

  // Toggle reel state
  const toggleReel = () => {
    let newActive = {...active};
    newActive.reel = !newActive.reel;
    newActive["type"] = "reel";

    setActiveGlobal(SET_ACTIVE_URL, newActive);
    dispatch(activeDispatch(newActive));
  };

  // Handle task changes
  const gliderTaskChange = async (tow) => {
    const el = await contentRef.current.getScrollElement();
    setEditTow(tow);
    setType(ActiveSelect.GLIDER_TASK_DETAIL);
    setLastPositionY(el.scrollTop);
  };

  const towTaskChange = (tow) => {
    setEditTow(tow);
    setType(ActiveSelect.TOW_TASK_DETAIL);
  };

  const setGliderTask = (task) => {
    const updatedTow = {...editTow, gliderTask: task};
    updateResource(REST_AEROTOW_URL, updatedTow);
    selectHome();
  };

  const setTowTask = (task) => {
    if (editTow) {
      const updatedTow = {...editTow, towTask: task};
      setActive('towTask', task);
      updateResource(REST_AEROTOW_URL, updatedTow);
    } else {
      setActive('towTask', task);
    }
    selectHome();
  };

  // Scroll to position
  const scrollToPosition = (positionY) => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollToPoint(0, positionY, 100);
    } else {
      console.log("No ref to scrollable content");
    }
  };

  // Start action
  const startAction = async () => {
    const reqActive = {...active};
    reqActive.flyDay = reverseDateToLocalDate(dateParam);

    const response = await sendStartAction(reqActive);
    if (response.code !== 0) {
      setShowToastMessage(true, response.message);
    }
  };

  // Set toast message
  const setShowToastMessage = (visible, message) => {
    setShowToast(visible);
    setToastMessage(message);
  };

  // Clear on close message
  const clearOnCloseMessage = () => {
    dispatch({type: 'CLOSE_RESPONSE', payload: null});
  };

  // Filter actions
  const filterPilotDispatch = (load) => dispatch(filterPilot(load));
  const filterGliderDispatch = (load) => dispatch(filterGlider(load));
  const filterTowingPlaneDispatch = (load) => dispatch(filterTowingPlane(load));
  const filterTowingPilotDispatch = (load) => dispatch(filterTowingPilot(load));
  const switchIncompleteDispatch = () => dispatch(filterIncomplete());
  const closeReceiveChange = () => dispatch(onCloseReceiveFalse());

  // Effect hooks to replace lifecycle methods
  useEffect(() => {
    const date = reverseDateToHuman(dateParam);

    if (!date) {
      navigate('/');
      return;
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    getFlyDay();

    return () => {
      clearInterval(intervalID);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [dateParam]);

  useEffect(() => {
    if (reduxFlyDay && (!flyDay || reduxFlyDay.time !== flyDay.time)) {
      setFlyDay(reduxFlyDay);
    } else if (flyDayStompConnectionStatus !== null && flyDayStompConnectionStatus !== channelConnected) {
      console.log("flyDayStompConnectionStatus:" + flyDayStompConnectionStatus);
      setChannelConnected(flyDayStompConnectionStatus);
    }
  }, [reduxFlyDay, flyDayStompConnectionStatus]);

  useEffect(() => {
    if (onCloseMessageChange) {
      selectHome();
      closeReceiveChange();
    }
  }, [onCloseMessageChange]);

  // Rendering components
  const renderDetail = () => {
    if (flyDay && flyDay.tows) {
      const aerotow = flyDay.tows.find(element => element.uuid === detailUUID);
      return (
          <AeroTowDetail
              tow={aerotow}
              home={selectHome}
              timeChange={timeChange}
              changeTow={changeTow}
              date={dateParam}
          />
      );
    }
    return null;
  };

  const renderActiveDetail = () => {
    const {towingPlane, lifter, glider, first, second, reel, towTask} = active;

    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => selectHome()} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
                <IonButton slot={'secondary'} onClick={() => switchIncompleteDispatch()}>
                  {incompleteFilter ? 'jen neúplné' : 'všechny'} <IonIcon icon={funnel}/>
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem onClick={() => toggleReel()}>
                <IonLabel>Naviják</IonLabel>
                <IonToggle value="navikák" checked={reel}/>
              </IonItem>
              {!reel &&
                  <>
                    <IonItem onClick={() => activeSelectFce(ActiveSelect.TOWINGPLANE)}>
                      <IonLabel>Vlečná:</IonLabel>
                      <div>
                        {towingPlane &&
                            `${towingPlane.name} ${towingPlane.registration}`
                        }
                        {!towingPlane && <span>Není</span>}
                      </div>
                    </IonItem>
                    <IonItem onClick={() => activeSelectFce(ActiveSelect.LIFTER)}>
                      <IonLabel>Vlekař:</IonLabel>
                      <div>
                        {completePilotName(lifter)}
                      </div>
                    </IonItem>
                  </>
              }
              {!(towTask && towTask.onlyTow) &&
                  <>
                    <IonItem onClick={() => activeSelectFce(ActiveSelect.GLIDER)}>
                      <IonLabel>Kluzák:</IonLabel>
                      <div>
                        {glider &&
                            `${glider.name} ${glider.registration}`
                        }
                        {!glider && <span>Neni</span>}
                      </div>
                    </IonItem>
                    <IonItem onClick={() => activeSelectFce(ActiveSelect.FIRST)}>
                      <IonLabel>První pilot:</IonLabel>
                      <div>
                        {completePilotName(first)}
                      </div>
                    </IonItem>
                    {glider && glider.twoSeat &&
                        <IonItem onClick={() => activeSelectFce(ActiveSelect.SECOND)}>
                          <IonLabel>Druhý pilot:</IonLabel>
                          <div>
                            {completePilotName(second)}
                          </div>
                        </IonItem>
                    }
                  </>
              }
              {!reel &&
                  <IonItem onClick={() => activeSelectFce(ActiveSelect.TOW_TASK_DETAIL)}>
                    <IonLabel>Úloha vlečné:</IonLabel>
                    <div>
                      {towTask &&
                          towTask.name
                      }
                      {!towTask && <span>vlek</span>}
                    </div>
                  </IonItem>
              }
            </IonList>
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                <IonButton disabled={false} expand='full' onClick={() => selectHome()} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
        </IonPage>
    );
  };

  const renderFilterChips = () => {
    if (flyDay == null) return null;

    const filteredList = filterList(
        flyDay.tows,
        filterPilotSelector,
        filterGliderSelector,
        filterTowingPlaneSelector,
        filterTowingPilotSelector
    );

    let timeArr = [0, 0];
    let timeArr1 = [0, 0];

    for (let i = 0; i < filteredList.length; i++) {
      const tow = filteredList[i];
      if (tow.start && tow.gliderLanding) {
        const t = subtractTimeArr(tow.gliderLanding, tow.start);
        timeArr[0] += t[0];
        timeArr[1] += t[1];
        timeArr = timeArrayCorrection(timeArr);
      }
      if (tow.start && tow.towingPlaneLanding) {
        const t1 = subtractTimeArr(tow.towingPlaneLanding, tow.start);
        timeArr1[0] += t1[0];
        timeArr1[1] += t1[1];
        timeArr1 = timeArrayCorrection(timeArr1);
      }
    }

    return (
        <IonCol size={'8'}>
          <IonToolbar>
            {filterPilotSelector &&
                <IonChip onClick={() => filterPilotDispatch(null)}>
                  <IonLabel>{filterPilotSelector.name} {filterPilotSelector.surname}</IonLabel>
                  <IonIcon icon={closeCircle}/>
                </IonChip>
            }
            {filterGliderSelector &&
                <IonChip onClick={() => filterGliderDispatch(null)}>
                  <IonLabel>{filterGliderSelector.name} ({filterGliderSelector.registration}) </IonLabel>
                  <IonIcon icon={closeCircle}/>
                </IonChip>
            }
            {(filterPilotSelector || filterGliderSelector) &&
                <IonLabel>
                  {flyDay.closed ?
                      <>{filteredList.length}x - {timeArrToString(timeArr)}</> :
                      <>{filteredList.length}x</>
                  }
                </IonLabel>
            }
            {filterTowingPlaneSelector &&
                <>
                  <IonChip onClick={() => filterTowingPlaneDispatch(null)}>
                    <IonLabel>{filterTowingPlaneSelector.name} ({filterTowingPlaneSelector.registration}) </IonLabel>
                    <IonIcon icon={closeCircle}/>
                  </IonChip>
                  <IonLabel>
                    {flyDay.closed ?
                        <>{filteredList.length}x - {timeArrToString(timeArr1)}</> :
                        <>{filteredList.length}x</>
                    }
                  </IonLabel>
                </>
            }
            {filterTowingPilotSelector &&
                <>
                  <IonChip onClick={() => filterTowingPilotDispatch(null)}>
                    <IonLabel>{filterTowingPilotSelector.surname} {filterTowingPilotSelector.name} </IonLabel>
                    <IonIcon icon={closeCircle}/>
                  </IonChip>
                  <IonLabel>
                    {flyDay.closed ?
                        <>{filteredList.length}x - {timeArrToString(timeArr1)}</> :
                        <>{filteredList.length}x</>
                    }
                  </IonLabel>
                </>
            }
          </IonToolbar>
        </IonCol>
    );
  };

  const renderConfigButton = () => {
    const {towingPlane, lifter, glider, first, second, reel, towTask} = active;
    const showConf = reel || towingPlane || lifter || glider || first || second;

    return (
        <IonCol size={'8'} onClick={() => selectActiveDetail()}>
          <div expand={'full'} className={'big-button'}>
            {showConf &&
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      {reel ?
                          <span>Naviják</span> :
                          <>
                            <PlaneView plane={towingPlane}/><br/>
                            <PilotView pilot={lifter}/>
                          </>
                      }
                    </IonCol>
                    <IonCol>
                      {(!towTask || !towTask.onlyTow) ?
                          <ActiveGlider glider={glider} first={first} second={second}
                                        activeSelectFce={activeSelectFce}/> :
                          <span>Úloha pouze pro vlečnou ({towTask.name})</span>
                      }
                    </IonCol>
                  </IonRow>
                </IonGrid>
            }
            {!showConf &&
                <h2 className={'text-center'}>Konfigurace</h2>
            }
          </div>
        </IonCol>
    );
  };

  const renderCloseDayAlert = () => {
    if (!onCloseMessage || !onCloseMessage.error) return null;

    return (
        <IonToast
            isOpen={onCloseMessage != null}
            onDidDismiss={() => clearOnCloseMessage()}
            message={onCloseMessage.message}
            buttons={[
              {
                text: 'Zavřít',
                role: 'cancel',
                handler: () => {
                }
              }
            ]}
        />
    );
  };

  const renderToastAlert = () => {
    return (
        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToastMessage(false, "")}
            message={toastMessage}
            duration={5000}
        />
    );
  };

  const renderFlyDay = () => {
    const markTowId = onCloseMessage && onCloseMessage.error ? onCloseMessage.id : null;
    const {towingPlane, lifter, glider, first, second, reel, towTask} = active;
    const onlyTow = lifter && towingPlane && towTask && towTask.onlyTow;
    const startDisabled = !((lifter && towingPlane && glider && first) || (reel && glider && first) || onlyTow);

    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonRow>
                <IonCol size={'4'}>
                  <IonLabel>
                    <h2 className={'date'}>
                      {channelConnected ?
                          <div className={"btn"}>
                            <strong>{flyDay && <CustomCalendar date={flyDay.day}/>}</strong>
                          </div> :
                          <span><strong>Odpojeno! ({reconnectTimeOut}s)</strong></span>
                      }
                    </h2>
                    <h2><UtcTime time={flyDay?.time} update={getFlyDay}/>{markTowId}</h2>
                  </IonLabel>
                </IonCol>
                {filterPilotSelector || filterGliderSelector || filterTowingPlaneSelector || filterTowingPilotSelector ?
                    renderFilterChips() :
                    renderConfigButton()
                }
              </IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent
              scrollEvents={true}
              ref={contentRef}
          >
            <IonMenuButton horizontal="start" slot="fixed" className={'fab-menu'}>
              <IonIcon icon={chevronForwardOutline}/>
            </IonMenuButton>

            {flyDay && flyDay.planeBookDayItems &&
                <PlaneBookDayItems items={flyDay.planeBookDayItems}/>
            }
            {flyDay && flyDay.tows &&
                <AeroTowList
                    aeroTowList={flyDay.tows}
                    timeChange={timeChange}
                    detail={showDetail}
                    taskChange={gliderTaskChange}
                    towTaskChange={towTaskChange}
                    markId={markTowId}
                    scrollToPosition={scrollToPosition}
                />
            }
            {renderCloseDayAlert()}
            {renderToastAlert()}
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                {canDownloadPdf ?
                    <IonButton
                        expand='full'
                        disabled={false}
                        href={concatUrlId(DOWNLOAD_PDF_URL, day.replace(/\./g, "-"))}
                        target={"_blank"}
                    >
                      Stáhnout Pdf
                    </IonButton> :
                    <IonButton
                        expand='full'
                        disabled={startDisabled}
                        href={undefined}
                        onClick={() => startAction()}
                    >
                      Start
                    </IonButton>
                }
              </IonCol>
            </IonRow>
          </IonFooter>
        </IonPage>
    );
  };

  const renderPilot = () => {
    let lifter = false;
    let selectMessage = "Vyber pilota";

    if (type === ActiveSelect.FIRST) {
      selectMessage = "Vyber prvního pilota kluzáku";
    } else if (type === ActiveSelect.SECOND) {
      selectMessage = "Vyber druhého pilota kluzáku";
    } else if (type === ActiveSelect.LIFTER) {
      lifter = true;
      selectMessage = "Vyber vlekaře";
    }

    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonButton slot={'start'} color={'primary'} onClick={() => selectActiveDetail()}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
                <IonTitle size={'small'}>{selectMessage}</IonTitle>
                {type === ActiveSelect.SECOND &&
                    <IonButton color={"primary"} slot={'end'} onClick={() => setPilot(null)}>Žádný
                      pilot</IonButton>
                }
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <PilotROList
                key={'pilotList'}
                resourceUrl={REST_PILOT_URL}
                view={type}
                active={active}
                setPilot={setPilot}
                findFce={findPilotFce}
                loadApiQuery={'active=true' + (lifter ? '&lifter=true' : '')}
            />
          </IonContent>
        </IonPage>
    );
  };

  const renderGlider = () => {
    const activeGlider = active ? active.glider : null;

    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonButton slot={'start'} color={'primary'} onClick={() => selectActiveDetail()}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
                <IonTitle>Vyber kluzák</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <GliderListRO
                key={'gliderList'}
                type={type}
                setGlider={setGlider}
                active={activeGlider}
                findFce={findGliderFce}
            />
          </IonContent>
        </IonPage>
    );
  };

  const renderTowingPlane = () => {
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonButton slot={'start'} color={'primary'} onClick={() => selectActiveDetail()}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
                <IonTitle>Vyber vlečnou</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
           <TowingPlaneList key={'planeList'} type={type} setPlane={setTowingPlane}
                            active={active ? active.towingPlane : null}/>
          </IonContent>
        </IonPage>
    );
  }

  const renderGliderTaskView = () => {
    const groupId = editTow.first && editTow.first.taskGroup ? editTow.first.taskGroup.id : null;
    return (
        <GliderTaskCompose tow={editTow} setTask={setGliderTask} back={selectHome} groupId={groupId} />
    );
  }

  const renderTowTaskView = () => {
    return (
        <TowTaskCompose tow={editTow} setTask={setTowTask} active={active} back={selectHome} />
    );
  }

  const renderType = () => {
    switch (type) {
      case ActiveSelect.HOME:
        return renderFlyDay();
      case ActiveSelect.ACTIVE_DETAIL:
        return renderActiveDetail();
      case ActiveSelect.GLIDER:
        return renderGlider();
      case ActiveSelect.FIRST:
      case ActiveSelect.SECOND:
      case ActiveSelect.LIFTER:
        return renderPilot();
      case ActiveSelect.TOWINGPLANE:
        return renderTowingPlane();
      case ActiveSelect.GLIDER_TASK_DETAIL:
        return renderGliderTaskView();
      case ActiveSelect.TOW_DETAIL:
        return renderDetail();
      case ActiveSelect.TOW_TASK_DETAIL:
        return renderTowTaskView();
      default:
        return renderFlyDay();
    }
  };



  return (
      <><SubscribingComponent dayDate={convertDateMinusToDot(params.dateParam)}/>{renderType()}</>
  )

}

export default FlyDay;

