import React from "react";
import { IonChip, IonIcon } from "@ionic/react";
import { TimeType } from "../common/constants";
import { TimeInput } from "./TimeInput";
import { copy, create, trash } from "ionicons/icons";
import TimeSubtraction from "../common/TimeSubtraction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";  // Use the useNavigate hook from react-router-dom
import { filterGlider, filterPilot } from "../reduce/actions";
import { firstLetter } from "../common/StringUtils";

const rowClassName = (index) => {
  return index % 2 === 0 ? "odd" : "even";
};

const OnlyTowCls = ({
                      id,
                      uuid,
                      towingPlane,
                      lifter,
                      glider,
                      first,
                      second,
                      start,
                      towingPlaneLanding,
                      gliderLanding,
                      note,
                      gliderTask,
                      towTask,
                      towTaskChange,
                      closed,
                      index,
                      typeIndex,
                      timeChange = (f) => f,
                      detail = (f) => f,
                      taskChange = (f) => f,
                      tow,
                      filterPilotDispatch,
                      filterGliderDispatch,
                      mark = false,
                    }) => {
  const navigate = useNavigate();  // useNavigate hook

  return (
      <>
        <tr
            className={
                rowClassName(index) + (mark ? " mark-error" : "")
            }
        >
          <td rowSpan={note ? 2 : 1} className={"start-time relative"}>
            {!closed && (
                <div>
                  <IonChip
                      onClick={() => detail(uuid)}
                      className={"edit-tow"}
                  >
                    uprav <IonIcon icon={create} />
                  </IonChip>
                </div>
            )}
            <strong className={"type-count"}>V{typeIndex + 1}</strong>
            <TimeInput
                className={"start-time"}
                value={start}
                id={id}
                type={TimeType.START_TIME}
                timeChange={timeChange}
                disabled={closed}
            />
          </td>
          <td className={"plane-tow"}>
            {towingPlane.name}
            <br />
            <small>{towingPlane.registration}</small>
          </td>
          <td className={"pilot-tow"}>
            {lifter.surname} {firstLetter(lifter.name)}
          </td>
          <td className={"tow-task"}>
            <span>{towTask ? towTask.name : "Úloha"}</span>
          </td>
          <td className={"tow-landing"}>
            <TimeInput
                value={towingPlaneLanding}
                id={id}
                type={TimeType.LANDING_TOWING_TIME}
                timeChange={timeChange}
                min={start}
                disabled={closed}
            />
            <TimeSubtraction t1={start} t2={towingPlaneLanding} />
          </td>
        </tr>
        {note && (
            <tr className={rowClassName(index)}>
              <td colSpan={"4"}>{note}</td>
            </tr>
        )}
      </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterPilotDispatch: (load) => dispatch(filterPilot(load)),
    filterGliderDispatch: (load) => dispatch(filterGlider(load)),
  };
};

const OnlyTow = connect(null, mapDispatchToProps)(OnlyTowCls);

export default OnlyTow;
