import React, {useEffect, useRef} from "react";
import {IonChip, IonIcon} from "@ionic/react";
import {TimeType} from "../common/constants";

import {copy, create} from "ionicons/icons";
import TimeSubtraction from "../common/TimeSubtraction";
import {TimeInput} from "../aerotow/TimeInput";
import {activeDispatch, filterGlider, filterPilot} from "../reduce/actions";
import {connect} from "react-redux";

const firstLetter = (name) => {
  return name ? name[0] + "." : ""
};

const rowClassName = (index)  => {
  return index % 2 === 0 ? 'odd' : 'even'
};

export const ReelCls = ({id, uuid, glider, tow, first, second, start, gliderTask, gliderLanding, note, index, typeIndex, taskChange, closed, timeChange=f=>f, filterPilotDispatch, filterGliderDispatch, mark=false, detail=f=>f, scrollToPosition=f=>f, setActive=f=>f, active, changeActive, canReopen}) => {

  let refRow = useRef(null);

  useEffect( () => {
    if (refRow && refRow.current && mark) {
      setTimeout(() => {
        scrollToPosition(refRow.current.offsetTop);
      }, 100);
    }
  },[mark]);

  return (
  <>
    <tr className={rowClassName(index) + (mark ? ' mark-error' : '')}>
      <td rowSpan={note ? 2 : 1} className={'start-time relative'} ref={refRow}>
        {!closed &&
        <div>
          <IonChip onClick={() => detail(uuid)} className={'edit-tow'}>
            uprav<IonIcon icon={create}/>
          </IonChip>
        </div>
        }
        <span className={'type-copy'} onClick={() => setActive(tow, active, changeActive)}>
            <strong className={"type-count"}>N{typeIndex + 1}</strong><IonIcon className={'copy-icon'} icon={copy} title={'Do konfigurace'}/>
          </span>
        <TimeInput className={"start-time"} value={start} id={id} type={TimeType.START_TIME} timeChange={timeChange}
                   disabled={closed}/>

      </td>
      <td className={'plane-glider'}>
        <a className={'btn btn-link'} onClick={()=>filterGliderDispatch(glider)}>
          {glider.name}<br/>
          <small>{glider.registration}</small>
        </a>
      </td>
      <td className={'pilot-glider'}>
        <a className={'btn btn-link'} onClick={()=>filterPilotDispatch(first)}>{first.surname} {firstLetter(first.name)}</a>
        {second ? <><br/><a className={'btn btn-link'} onClick={()=>filterPilotDispatch(second)}>{second.surname + ' ' + firstLetter(second.name)}</a> </> : ''}</td>
      <td>
        {!closed || canReopen || !gliderTask ?
            <IonChip className={'font-l'} onClick={() => taskChange(tow)} >
              {gliderTask ? gliderTask.name : "Úloha"}
            </IonChip>
            :
            <span>{gliderTask ? gliderTask.name : "Úloha"}</span>
        }
      </td>

      <td className={'glider-landing'}>
        <TimeInput value={gliderLanding} id={id} type={TimeType.LANDING_GLIDER_TIME} timeChange={timeChange}
                   disabled={closed}/>
        <TimeSubtraction t1={start} t2={gliderLanding}/>
      </td>
    </tr>
    {note &&
    <tr className={rowClassName(index)}>
      <td colSpan={'4'}>
        {note}
      </td>
    </tr>
    }
  </>
  );

}

const mapStateToProps = function(state) {
  return {
    active: state ? state.active : {},
    canReopen: state ? state.canReopen : {},
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    filterPilotDispatch(load) {
      {
        dispatch(filterPilot(load))
      }
    },
    filterGliderDispatch(load) {
      dispatch(filterGlider(load))
    },
    changeActive(load) {
      dispatch(activeDispatch(load))
    },
  }
}


const Reel = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReelCls);


export default Reel;