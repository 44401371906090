import React, { useEffect } from "react";
import { getCurrentUser } from "../common/ApiUtils";
import C from "../common/ReductTypeConstants";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const LoginHandler = ({ dispatchUser }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const loadCurrentUser = () => {
    if (location.pathname.startsWith("/forgottenPassword/") ||
        location.pathname.startsWith("/aprslogbook")) {
      return;
    }

    getCurrentUser()
        .then(response => {
          dispatchUser(response);
        })
        .catch(error => {
          console.error("Failed to load current user:", error);
          navigate("/login"); // React Router v6 way of redirecting
        });
  };

  useEffect(() => {
    loadCurrentUser();
  }, []); // Runs once on component mount

  return null; // No UI needed
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUser: (load) => dispatch({ type: C.USER, payload: load }),
});

export default connect(null, mapDispatchToProps)(LoginHandler);
