import {
    IonMenu,
    IonContent,
    IonList,
    IonIcon,
    IonLabel,
    IonItem,
    IonFooter,

} from "@ionic/react";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; // React Router v6

import {
    timeSharp,
    pin,
    logOut,
    hammerOutline,
    radio,
    paw,
    keyOutline,
    airplaneOutline,
    clipboardOutline,
    flagOutline,
    personOutline,
    libraryOutline,
    pawOutline,
    cloudDownloadOutline,
    albumsOutline,
    manOutline,
    mapOutline
} from 'ionicons/icons';

import {
    hasRole,
    loadResource,
    sendCloseDate,
    sendReOpenDate
} from "../common/ApiUtils";

import { fromHumanToLocalDate } from "../common/TimeUtils";
import { logout, onCloseReceive, onReOpenReceive, infoDataDispatch } from "../reduce/actions";

import {
    ACCESS_TOKEN,
    AISVIEW_METAR_URL,
    concatUrlId,
    DOWNLOAD_PDF_URL,
    ROLE_ADMIN,
    ROLE_USER
} from "../common/constants";

import WeatherModal from "./WeatherModal";
import {Label} from "reactstrap";


const pages = [
    { title: 'Šmírka', path: '/', icon: clipboardOutline, role: ROLE_USER },
    { title: 'Flotila', path: '/gliders', icon: airplaneOutline, role: ROLE_USER },
    { title: 'Piloti', path: '/pilots', icon: manOutline, role: ROLE_USER },
    { title: 'Letiště', path: '/place', icon: pin, role: ROLE_USER },
    { title: 'Úlohy', path: '/task', icon: flagOutline, role: ROLE_USER },
    { title: 'Deník kluzáku', path: '/logbook', icon: libraryOutline, role: ROLE_USER },
    { title: 'Uživatelé', path: '/users', icon: personOutline, role: ROLE_ADMIN },
    { title: 'Tracking', path: '/tracking', icon: pawOutline, role: ROLE_USER },
    { title: 'Aprs logbook', path: '/aprslogbook', icon: paw, role: ROLE_USER },
    { title: 'Aprs aktivní', path: '/aprs', icon: radio, role: ROLE_USER },
    { title: 'Uživatelské konto', path: '/user', icon: keyOutline, role: ROLE_USER },
    { title: 'Plánovací tabulka', path: 'https://docs.google.com/spreadsheets/d/1ZAp-jrWOq7Y52KOJGehuyQqFE7LrgnDbxeY3Hh8PC-w/edit?gid=0#gid=0', icon: albumsOutline, role: ROLE_USER, external: true },
    { title: 'Glider tracker', path: 'https://glidertracker.org/#lst=http://www.cambarus.cz/lkhs/lkhs.csv&lat=49.578&lon=14.759&z=9&id=&nowelcome', icon: mapOutline, role: ROLE_USER, external: true },
];

const WeatherSvgIcon = () => (
    <svg viewBox="0 0 64 64" style={{ height: '25px', fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
        <path
            d="M23.634 36.002a2.284 2.284 0 0 0 2.162-1.554 6.098 6.098 0 0 1 5.813-4.214 6.166 6.166 0 0 1 5.842 4.204 2.25 2.25 0 0 0 2.133 1.532c.964.032 1.949.032 1.949.032a6.498 6.498 0 0 1 6.5 6.5v.003a6.501 6.501 0 0 1-6.5 6.499H21.684a6.5 6.5 0 0 1-6.499-6.499v-.003a6.497 6.497 0 0 1 6.499-6.5h1.95z"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
        <path
            d="M11.864 44.68a6.499 6.499 0 0 1-4.5-6.184v-.003a6.499 6.499 0 0 1 6.499-6.5h3.778a6.163 6.163 0 0 1 6.147-5.768c2.76 0 5.099 1.82 5.88 4.324M37.232 26.996a6.107 6.107 0 0 1 2.98-.771 6.165 6.165 0 0 1 5.842 4.205 2.251 2.251 0 0 0 2.133 1.531c.964.032 1.95.032 1.95.032a6.501 6.501 0 0 1 6.499 6.5v.003a6.499 6.499 0 0 1-6.499 6.5h-2.6"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
        <path
            d="M38.864 25.996c0-3.312 2.688-6 6-6a6.003 6.003 0 0 1 5.658 8M44.864 14.996v2M52.642 18.217l-1.414 1.415M55.864 25.996h-2M37.085 18.217l1.415 1.415"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
    </svg>
);

const SideMenu = ({ canClose, day, canDownloadPdf, currentUser, dispatchLogout, isAuthenticated, onCloseDay, onReOpenDay, canReopen, dispatchInfoData, infoData }) => {
    const [activePage, setActivePage] = useState(pages[0].title);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate(); // Use React Router v6's navigate function
    const menuRef = useRef(null);


    useEffect(() => {
        const loadInfoData = async () => {
            const res = await loadResource(AISVIEW_METAR_URL);
            dispatchInfoData(res.data);
        };
        loadInfoData();
    }, []);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const hideMenu = () => menuRef.current?.close();

    const navigateToPage = (page) => {
        if (page.external) {
            window.location.href = page.path;
        } else {
            navigate(page.path);
            setActivePage(page.title);
            hideMenu();
        }
    };

    const closeDay = async () => {
        const res = await sendCloseDate({ flyDay: fromHumanToLocalDate(day) });
        hideMenu();
        onCloseDay(res);
    };

    const reopenDay = async () => {
        const res = await sendReOpenDate({ flyDay: fromHumanToLocalDate(day) });
        hideMenu();
        onReOpenDay(res);
    };

    const handleLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        dispatchLogout();
        navigate("/");
        window.location.reload();
    };

    const renderInfoData = () => {
        if (!infoData) {
            return null;
        }

        const observeDate = new Date(infoData.observeDate);
        const threeHoursAgo = new Date(Date.now() - 3 * 60 * 60 * 1000);
        const isRecent = observeDate > threeHoursAgo;


        const infoDataStyle = !isRecent ? { backgroundColor: 'yellow' } : {};

        return (
            <IonItem style={infoDataStyle} className="info-data" onClick={() => {setShowModal(true)}} slot={"start"}>
                {/*<p><strong>METAR:</strong> {infoData.metar}</p>*/}
                <WeatherSvgIcon/>
                <Label>QNH: {infoData.qnh}</Label>

                {/*<p><strong>Visibility:</strong> {infoData.visibility}</p>
                <p><strong>Wind:</strong> {infoData.wind}</p>
                <p><strong>Observation Date:</strong> {formatDate(observeDate)}</p>
                <p><strong>Temperature:</strong> {infoData.temperature}</p>
                <p><strong>{infoData.sunriseSunsetElement.txt}:</strong> {infoData.sunriseSunset}</p> */}

            </IonItem>
        );
    };

    return (
        <IonMenu contentId="main" ref={menuRef}>
            <IonContent>
                <IonList>
                    {pages.filter((page) => hasRole(page.role, currentUser)).map((page) => (
                        <IonItem button key={page.title} color={page.title === activePage ? 'primary' : ''} onClick={() => navigateToPage(page)}>
                            <IonIcon icon={page.icon} />
                            <IonLabel>{page.title}</IonLabel>
                        </IonItem>
                    ))}

                    {canClose && (
                        <IonItem button onClick={closeDay}>
                            <IonIcon icon={timeSharp} />
                            <IonLabel>Uzavřít den</IonLabel>
                        </IonItem>
                    )}

                    {canDownloadPdf && (
                        <IonItem button download={'neco'} href={concatUrlId(DOWNLOAD_PDF_URL, day.replace(/\./g, "-"))} target="_blank">
                            <IonIcon icon={cloudDownloadOutline} />
                            <IonLabel>Stáhnout PDF</IonLabel>
                        </IonItem>
                    )}

                    {canReopen && (
                        <IonItem button onClick={reopenDay}>
                            <IonIcon icon={hammerOutline} />
                            <IonLabel>Znovu otevřít</IonLabel>
                        </IonItem>
                    )}

                </IonList>
            </IonContent>

            {currentUser && (
                <IonFooter>
                    {infoData && <WeatherModal isOpen={showModal} onClose={closeModal} infoData={infoData} />}
                    <IonList>
                        {renderInfoData()}
                        <IonItem button onClick={handleLogout}>
                            <IonIcon icon={logOut} />
                            <IonLabel>Odhlásit ({currentUser.name})</IonLabel>
                        </IonItem>
                    </IonList>
                </IonFooter>
            )}
        </IonMenu>
    );
};

const mapStateToProps = (state) => ({
    canClose: state?.canClose ?? false,
    canDownloadPdf: state?.canDownloadPdf ?? false,
    day: state?.day ?? "",
    isAuthenticated: state?.isAuthenticated ?? false,
    currentUser: state?.currentUser ?? null,
    canReopen: state?.canReopen ?? false,
    infoData: state?.infoData ?? false,
});

const mapDispatchToProps = (dispatch) => ({
    onCloseDay: (response) => dispatch(onCloseReceive(response)),
    dispatchLogout: () => dispatch(logout()),
    onReOpenDay: (response) => dispatch(onReOpenReceive(response)),
    dispatchInfoData: (response) => dispatch(infoDataDispatch(response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
