import React from 'react';
import '@ionic/react/css/core.css';
import {
  IonApp
} from '@ionic/react';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


import { Provider } from 'react-redux'
import storeFactory from "./store/Store";

import Main from "./Main";

import { setupIonicReact } from '@ionic/react';

setupIonicReact({
    rippleEffect: false,
    innerHTMLTemplatesEnabled: true,
    mode: 'md',
});

const store = storeFactory();

class App extends React.Component {

  render() {
    return (
        <Provider store={store}>
            <div id="app">
              <IonApp>
                <Main />
              </IonApp>
            </div>
        </Provider>
    )
  }

}

export default App;
